<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">订餐详情</div>
      <div class="nav_left_btn" @click="toOrder">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
    </div>
    <div class="content">
      <div v-for="item in originOrder.productList" :key="item.id" class="card">
        <img
          :src="item.logo ? `${apiURL}/image/?name=${item.logo}` : dish"
          alt
          @click="showImg(item.logo)"
        />
        <div class="detail">
          <div class="name">{{ item.name }}</div>
          <div class="num">
            <div class="price">
              ¥
              <span class="price-num">
                {{
                (item.price / 100).toFixed(2)
                }}
              </span>
            </div>
            <van-stepper
              v-model="item.num"
              min="0"
              :max="item.stock"
              @change="onNumberChange(item.id, $event)"
              :default-value="0"
              integer
              button-size="32"
            />
          </div>
          <div class="stock">库存：{{ item.stock }}</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <van-popup v-model="show" round position="bottom" style="padding-bottom: 3rem">
        <div class="pop-content">
          <div class="title">
            <div class="choosed">已选菜品</div>
            <div class="clear" @click="clearDish">
              <img :src="clear" alt style="width: 1rem; height: 1rem" />
              清空
            </div>
          </div>
          <div class="content">
            <div v-if="num(originOrder.productList)">
              <div v-for="item in originOrder.productList" :key="item.id">
                <div v-if="item.num" class="card">
                  <img
                    :src="
                      item.logo ? `${apiURL}/image/?name=${item.logo}` : dish
                    "
                    alt
                  />
                  <div class="detail">
                    <div class="name">{{ item.name }}</div>
                    <div class="num">
                      <div class="price">
                        ¥
                        <span class="price-num">
                          {{
                          (item.price / 100).toFixed(2)
                          }}
                        </span>
                      </div>
                      <van-stepper
                        v-model="item.num"
                        min="0"
                        :max="item.stock"
                        @change="onNumberChange(item.id, $event)"
                        :default-value="0"
                        integer
                        button-size="28"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
      <div class="total">
        <div class="text">
          合计：
          <span class="sign">¥</span>
          <span v-if="amount" class="total-num">
            {{
            (amount / 100).toFixed(2)
            }}
          </span>
          <span :class="amount ? 'no-total-num' : 'total-num'">
            {{
            (originalCost / 100).toFixed(2)
            }}
          </span>
        </div>
        <div>
          <van-button
            round
            :color="
              totalNum ? 'linear-gradient(to right, #ff6034, #ee0a24)' : '#ccc'
            "
            @click="totalNum?show2=true:show2=false"
          >提交订单</van-button>
        </div>
      </div>
    </div>
    <van-overlay :show="loading" class="init">
      <van-loading color="#1989fa" />
    </van-overlay>
    <van-popup
      class="payment_popup"
      v-model="show2"
      position="bottom"
      :style="{ height: '60%' }"
      :close-on-click-overlay="false"
      :round="true"
    >
      <div class="payment_in">
        <div class="payment_top">
          <img :src="PayClose" alt @click="show2=false" />
        </div>
        <div class="payment_money">
          <h1>{{amount?(amount / 100).toFixed(2):(originalCost / 100).toFixed(2)}}</h1>
          <p>元</p>
        </div>
        <div class="payment_order">
          <p class="left">订餐金额</p>
          <p class="right">￥{{amount?(amount / 100).toFixed(2):(originalCost / 100).toFixed(2)}}</p>
        </div>
        <div class="payment_select">
          <van-radio-group v-model="result">
            <van-cell-group>
              <van-cell :title="'卡内余额'" clickable @click="result = 1">
                <template #label>
                  <span
                    style="margin-left:0.5rem"
                  >(可用余额:￥{{(Number(personInfo.balance+personInfo.coupon+personInfo.allowance)/100).toFixed(2)}})</span>
                </template>
                <template #right-icon>
                  <van-radio checked-color="#ee0a24" :name="1" />
                </template>
              </van-cell>
              <van-cell
                title="数字人民币"
                clickable
                @click="result = 2"
                v-if="paymentSetting.icbcDcep&& paymentSetting.icbcDcep.appId !=='' && paymentSetting.icbcDcep.privateKey !==''"
              >
                <template #right-icon>
                  <van-radio checked-color="#ee0a24" :name="2" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <div class="payment_btn">
          <van-button round @click="onSubmit" :loading="isClick">确认支付</van-button>
        </div>
      </div>
    </van-popup>
    <div v-html="htmlText">{{htmlText}}</div>
  </div>
</template>

<script>
import Vue from "vue";
import { isArray, floor } from "lodash";
import cancel from "@/assets/cancel.png";
import dish from "@/assets/dish.png";
import clear from "@/assets/clear.png";
import {
  mealDetail,
  mealOrder,
  submitAPI,
  canOrderDcepPayAPI,
} from "@/api/order";
import { Toast, ImagePreview, Dialog } from "vant";
import moment from "moment";
import PayClose from "@/assets/pay_close.png";

export default {
  name: "Detail",
  data() {
    return {
      PayClose,
      dish,
      totalNum: 0,
      loading: true,
      id: "",
      clear,
      show: false,
      apiURL: process.env.VUE_APP_BASE_API,
      cancel,
      order: [],
      originOrder: {},
      // 订餐地址
      msg: "",
      // false 堂食    true 外送
      flag: false,
      show2: false,
      list: [
        {
          label: "工银e支付",
          value: 1,
        },
        {
          label: "数字人民币",
          value: 2,
        },
      ],
      result: 1,
      htmlText: "",
      personInfo: {},
      paymentSetting: {},
      isClick: false,
    };
  },
  computed: {
    originalCost() {
      const list = this.originOrder.productList;
      let total = 0;
      isArray(list) &&
        list.map((item) => {
          total += item.num * item.price;
        });
      return total;
    },
    amount() {
      let { discountConfig } = this.$store.getters;
      if (!discountConfig) discountConfig = 100;
      console.log(discountConfig);
      const list = this.originOrder.productList;
      let total = 0;
      isArray(list) &&
        list.map((item) => {
          total += item.num * item.price;
        });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return floor((total * discountConfig) / 100);
    },
    num() {
      return (list) => {
        let amount = 0;
        list &&
          list.length > 0 &&
          list.map((item) => {
            if (item.num) amount += item.num;
          });
        this.totalNum = amount;
        if (amount === 0) this.show = false;
        return !!amount;
      };
    },
  },
  created() {
    this.personInfo = JSON.parse(sessionStorage.getItem("personInfo"));
    this.paymentSetting = JSON.parse(
      this.personInfo.organization.paymentSetting
    );
    console.log(this.paymentSetting, "this.paymentSetting");
    const { id, msg, flag } = this.$route.params;
    this.msg = msg;
    this.flag = flag;

    if (!id) {
      this.$router.push({ name: "Home" });
      return;
    }
    this.id = id;
    this.fetch(id);
  },
  methods: {
    onNumberChange(id, current) {
      const list = this.originOrder.productList;
      if (!isArray(list)) return;
      const index = list.findIndex((item) => item.id === id);
      if (index === -1) return;
      const item = list[index];
      if (Number(current) > Number(item.stock)) {
        Toast({
          duration: 1500,
          message: "库存不足",
        });
        this.$nextTick(() => {
          Vue.set(this.originOrder.productList, index, {
            ...item,
            num: item.stock,
          });
        });
      }
    },

    // 点击菜品图片放大显示
    showImg(logo) {
      const { apiURL } = this;
      if (logo) {
        ImagePreview({
          images: [`${apiURL}/image/?name=${logo}`],
          closeable: true,
        });
      } else {
        ImagePreview({
          images: [this.dish],
          closeable: true,
        });
      }
    },
    async onSubmit() {
      this.isClick = true;
      const {
        originOrder: { productList, endTime },
        id,
        totalNum,
        originalCost,
        amount,
        msg,
        flag,
      } = this;
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      const before = moment(endTime).isBefore(now, "second");
      if (before) {
        Toast("已过订餐时间，订餐失败！");
        this.isClick = false;
        return;
      }
      if (!totalNum) {
        this.isClick = false;
        return;
      }
      const products =
        (productList &&
          productList.length > 0 &&
          productList.filter((item) => {
            if (item.num) {
              return {
                id: item.id,
                name: item.name,
                logo: item.logo,
                price: item.price,
                num: item.num,
              };
            }
          })) ||
        [];
      const reduceCost = originalCost - amount;
      const order = {
        mealId: id,
        amount,
        originalCost,
        reduceCost,
        products,
        address: msg,
        mode: flag ? "take_out_meal" : "hall_meal",
      };
      Dialog.confirm({
        title: "提示",
        message: "确定预定此餐吗",
      })
        .then(async () => {
          if (this.result === 1) {
            const res = await submitAPI({ order });
            const { code, message } = res;
            if (code === 0) {
              Toast.success("预定成功");
              this.$router.push({ name: "Order", params: { active: 1 } });
              this.isClick = false;
            } else {
              console.log(message);
              this.isClick = false;
            }
          } else {
            const res = await canOrderDcepPayAPI({ order });
            if (res.code === 0 && res.data) {
              this.htmlText = res.data;
              this.$nextTick(() => {
                document.forms[0].submit();
                this.isClick = false;
              }).catch(() => {
                this.isClick = false;
              });
            } else {
              console.log(message);
              this.isClick = false;
            }
          }
        })
        .catch(() => {
          // on cancel
          this.show2 = false;
          this.result = 1;
          this.isClick = false;
        });
    },
    // 清空购物车
    clearDish() {
      const {
        originOrder: { productList },
      } = this;
      this.originOrder.productList =
        productList &&
        productList.length > 0 &&
        productList.map((item) => {
          item.num = 0;
          return { ...item };
        });
    },
    async fetch(id) {
      const res = await mealDetail(id);
      const { code, data, message } = res;
      if (code === 0) {
        this.originOrder = data;
        this.loading = false;
      } else {
        console.log(message);
      }
    },
    toOrder() {
      this.$router.push({ name: "Order" });
    },
    showDetail() {
      // this.show = true;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    z-index: 100;
    //position: relative;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  & > .content {
    padding: 4rem 1rem 4.3rem;
    .card {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 0.625rem 1rem;
      box-shadow: 0 0 0.875rem #dcdcdc;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      img {
        width: 5rem;
        height: 5rem;
        border-radius: 0.3rem;
      }
      .detail {
        margin-left: 1rem;
        width: 100%;
        .name {
          font-weight: 600;
          font-size: 1.125rem;
        }
        .num {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          .price {
            color: red;
            font-size: 0.875rem;
            .price-num {
              font-size: 1.125rem;
            }
          }
        }
        .stock {
          font-size: 0.875rem;
          text-align: right;
          margin-top: 0.5rem;
        }
      }
    }
  }
  & > .footer {
    .total {
      z-index: 2000;
      position: fixed;
      bottom: 0;
      box-shadow: 0 -5px 5px -5px #f2f2f2;
      background-color: #fff;
      width: 100%;
      padding: 0.625rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      .text {
        color: #333;
        font-weight: 600;
        .sign {
          font-size: 0.625rem;
          color: red;
        }
        .total-num {
          font-size: 1.25rem;
          color: red;
          font-weight: 600;
          margin-left: 0.2rem;
        }
        .no-total-num {
          font-weight: normal;
          color: #333;
          text-decoration: line-through;
          margin-left: 6px;
        }
      }
    }
    .pop-content {
      padding: 1rem;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .choosed {
          color: #333;
          font-weight: 600;
        }
        .clear {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
        }
      }
      .content {
        margin-top: 0.875rem;
        padding: 0 0 0.35rem;
        max-height: 30rem;
        box-sizing: border-box;
        overflow-x: scroll;
        .card {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          img {
            width: 4rem;
            height: 4rem;
            border-radius: 0.3rem;
          }
          .detail {
            margin-left: 1rem;
            width: 100%;
            .name {
              font-weight: 600;
              font-size: 1rem;
            }
            .num {
              display: flex;
              justify-content: space-between;
              margin-top: 0.5rem;
              .price {
                color: red;
                font-size: 0.75rem;
                .price-num {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.payment_popup {
  width: 100%;
  background: rgba(248, 248, 248);
  .payment_in {
    padding: 0.5rem;
    .payment_top {
      text-align: right;
      height: 1.5rem;
      img {
        width: 1.5rem;
      }
    }
    .payment_money {
      display: flex;
      justify-content: center;
      color: #000;
      h1 {
        margin: 0;
        font-weight: 900;
      }
      p {
        font-weight: 900;
        margin-left: 0.5rem;
      }
    }
    .payment_order {
      height: 2rem;
      padding: 0 1rem;
      background: rgba(255, 255, 255);
      line-height: 2rem;
      font-size: 0.9rem;
      color: #000;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      p {
        height: 2rem;
        margin: 0;
      }
    }
    .payment_select {
      padding: 0 0.5rem;
      background: rgba(255, 255, 255);
      font-size: 1.5rem;
    }
    .payment_btn {
      position: fixed;
      bottom: 0.5rem;
      width: 100%;
      text-align: center;
      .van-button {
        width: 80%;
        background: rgba(238, 130, 128);
        color: white;
        font-size: 1rem;
      }
    }
  }
}
::v-deep .van-cell::after {
  border: none !important;
}
::v-deep .van-cell__title,
.van-cell__value {
  font-size: 1rem !important;
  display: flex !important;
}
::v-deep .van-button--round {
  border-radius: 999px;
  height: 2.55rem;
  padding: 0 1.25rem;
  font-size: 1rem;
}
::v-deep .init.van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 4000;
}
::v-deep .footer .van-popup--bottom {
  z-index: 1009 !important;
}
::v-deep .footer .van-overlay {
  z-index: 1005 !important;
}
</style>
